export const companySetupSections = [
	{
		sectionTitle: '1. Company Setup',
		tasks: [
			{
				title: 'Invite company administrators',
				description: 'Send invitation to company officers.',
				buttonText: 'Invite',
				showRadio: true,
				companyInviteType: 'administrators',
			},
			{
				title: 'Invite Board members',
				description:
					'Adding board members is necessary in order to send out board consents. At least one board member must be a Director.',
				buttonText: 'Invite',
				showRadio: true,
				companyInviteType: 'boardMember',
			},
			{
				title: 'Set roles and signatories',
				description:
					'Designate the company officers who will be electronically signing securities and approve exercises.',
				buttonText: 'Invite',
				showRadio: true,
				companyInviteType: 'rolesSignatories',
			},
			{
				title: 'Invite legal contact',
				description:
					'Ask your law firm who your point of contact for onboarding will be.',
				buttonText: 'Invite',
				showRadio: true,
				companyInviteType: 'legalContact',
			},
			{
				title: 'Fill out company profile',
				description:
					"Add your company's incorporation information and address.",
				buttonText: 'Complete',
				showRadio: true,
				companyInviteType: 'companyProfile',
			},
			{
				title: 'Upload document',
				description: "Add your cap table and company's documents.",
				buttonText: 'Upload',
				showRadio: true,
				companyInviteType: 'uploadDocument',
			},
		],
	},
	{
		sectionTitle: '2. Existing Cap Table Spreadsheet',
		tasks: [
			{
				title: 'Build equity plan document sets',
				description:
					'Create and upload document sets in the templates section. You will attach these to your securities on the onboarding spreadsheet.',
				buttonText: 'Go to task',
				showRadio: true,
				companyInviteType: 'equityPlan',
			},
			{
				title: 'Import existing cap table spreadsheet',
				description: 'Use the Excel cap table importer.',
				buttonText: 'Go to the task',
				showRadio: true,
				companyInviteType: 'importCapTable',
			},
		],
	},
	{
		sectionTitle: '3. Add cap table details',
		tasks: [
			{
				title: 'Provide stakeholder details',
				description:
					'Download the spreadsheet and populate or correct the stakeholder data.',
				buttonText: 'Go to task',
				showRadio: true,
				companyInviteType: 'stakeholderDetails',
			},
		],
	},
	{
		sectionTitle: '4. Finishing up',
		tasks: [
			{
				title: 'Verify rights and preferences',
				description:
					'The Simplici Captable experience begins with importing great data. We’ve highlighted a few areas for review that have historically given companies trouble.',
				buttonText: 'Go to task',
				showRadio: true,
				companyInviteType: 'verifyRights',
			},
			{
				title: 'Verify cap table',
				description:
					'Verify and sign off on your cap table data, so we can release securities for officer signature.',
				buttonText: 'Review',
				showRadio: true,
				companyInviteType: 'verifyCapTable',
			},
		],
	},
];
