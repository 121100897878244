import { useRecoilState } from 'recoil';
import { API_URL } from '../../../constants/api';
import { useNetwork } from '../../../hooks';
import { memberTitlesState } from '../store';

export const useSignatory = () => {
	const { get } = useNetwork();
	const [memberTitle, setMemberTitle] = useRecoilState(memberTitlesState);

	const getTitle = async (selectedCompanyDetailsId:string) => {
		const resp = await get(
			`${API_URL.STAKEHOLDER}?companyId=${selectedCompanyDetailsId}&type=all`
		);

		// Check if the response has data
		if (resp?.data) {
			setMemberTitle(resp?.data ?? []);
		}
	};
	return { getTitle, memberTitle };
};
