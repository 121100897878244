import { atom } from 'recoil';
import { IInviteLegalContactState } from './type';

// Atom for modal open state
export const IsOpenInviteLegalContactModal = atom<boolean>({
	key: 'is-open-invite-legal-contact-modal',
	default: false,
});

// Atom for invite legal contact state
export const InviteLegalContactState = atom<IInviteLegalContactState>({
	key: 'invite-legal-contact-state',
	default: {
		firstName: '',
		lastName: '',
		email: '',
		countryCode: '+1',
		phoneNumber: '',
		lawFirmName: [],
	},
});