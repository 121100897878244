import React from 'react';
import { convertFileToBase64 } from '../../../../utils';

import './company-form-header.scss';
import { useNotification } from '../../../../@storybook';

interface ICompanyFormHeader {
	logo: File | string | null; // State passed down from parent
	onLogoUpload: (file: string) => void;
}

export const CompanyFormHeader: React.FC<ICompanyFormHeader> = ({
	logo,
	onLogoUpload,
}) => {
	const { errorNotification } = useNotification();

	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files[0]) {
			const file = e.target.files[0];
			const validTypes = ['image/png', 'image/jpeg', 'image/gif'];
			if (!validTypes.includes(file.type)) {
				errorNotification(
					'Invalid file type. Please upload a PNG, JPEG, or GIF image.'
				);
				return;
			}
			// Notify parent component with the selected file
			try {
				const base64File = await convertFileToBase64(file);
				onLogoUpload(file as any); // Update parent state with the selected file
				onLogoUpload(base64File as string); // Optional: Pass base64 if needed
			} catch (error) {
				console.error('Error converting file to Base64:', error);
			}
		}
	};
	return (
		<div className="CompanyFormHeader">
			<div className="CompanyFormHeader__header">
				<div className="CompanyFormHeader__title">
					Welcome to Simplici Captable
				</div>
				<p className="CompanyFormHeader__subTitle">
					You are almost there. Please complete the form below to continue.
				</p>
			</div>
			<div className="logo-section">
				<div className="logo-container">
					{logo ? (
						<img
							src={typeof logo === 'string' ? logo : URL.createObjectURL(logo)}
							alt="Company Logo"
							className="logo-preview"
						/>
					) : (
						<div className="logo-box">
							<i className="ri-building-fill" />
						</div>
					)}
				</div>
				<div className="logo-details">
					<label htmlFor="logo-upload" className="change-logo-button">
						Change company logo
					</label>
					<input
						type="file"
						id="logo-upload"
						accept="image/png, image/jpeg, image/gif"
						onChange={handleFileChange}
						className="file-input"
					/>
					<p className="file-restrictions">JPG, GIF or PNG. 10MB max.</p>
				</div>
			</div>
		</div>
	);
};