export const navData = [
  {
    header: "Setup",
    items: [
      {
        label: "Setup",
        icon: "ri-list-settings-line",
        link: "/setup",
      },
      {
        label: "Overview",
        icon: "ri-home-2-line",
        link: "/overview",
      },
      {
        label: "Captable",
        icon: "ri-pie-chart-line",
        link: "/captable",
        children: [
          { label: "Captable Overview", link: "/captable-overview" },
          { label: "Captable Access", link: "/captable-access" },
          { label: "Review captable health", link: "/review-captable-health" },
        ],
      },
    ],
  },
  {
    header: "Company",
    items: [
      // {
      //   label: "Team",
      //   icon: "ri-group-line",
      //   link: "/team",
      // },
      {
        label: "Settings",
        icon: "ri-settings-2-line",
        link: "/settings",
      },
    ],
  },
];
