import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useOutsideClick } from '../../hooks/use-outside-click';
import { selectedCompany } from '../company-registration-form/state';
import { ICompany } from '../company-registration-form/store';
import { CompanyListPopup } from './components';
import { Logo } from '../left-nav';
import { selectedCompanyState } from './store';

import { useNetwork } from '../../hooks';
import { API_URL } from '../../constants/api';
import { roleDataState, signatoryDataState } from '../settings/store';

import './header.scss';
import { useTeamData } from '../settings/store/hooks';

export const Header = () => {
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const companyList = useRecoilValue(selectedCompany);
	const setTeamData = useSetRecoilState(roleDataState); // Replace `any[]` with appropriate type if you know the structure of the response
	const setSignatoryData = useSetRecoilState(signatoryDataState);
	const navigate = useNavigate();
	const {fetchSignatoryData} = useTeamData()
	const sortedCompanyList = useMemo(
		() => companyList.slice().reverse(),
		[companyList]
	);
	// Use Recoil state for selected company details
	const [selectedCompanyDetails, setSelectedCompanyDetails] =
		useRecoilState(selectedCompanyState);

	const popupRef = useRef<HTMLDivElement>(null); // Ref for the popup
	const { get } = useNetwork();

	useEffect(() => {
		// Set default selected company if not already set
		if (companyList?.length > 0) {
			const getSelectedCompany = companyList[companyList.length - 1]
			setSelectedCompanyDetails(getSelectedCompany);
			fetchSignatoryData(getSelectedCompany?.id);
		}
	}, [companyList]);


	// Close popup when clicking outside
	useOutsideClick(popupRef, () => {
		if (isPopupOpen) {
			setIsPopupOpen(false);
		}
	});

	const togglePopup = useCallback(() => {
		setIsPopupOpen((prev) => !prev);
	}, [setIsPopupOpen]);

	const fetchTeamData = useCallback(
		async (selectedCompanyDetailsId: string) => {
			try {
				const response = await get(
					`${API_URL.STAKEHOLDER}?companyId=${selectedCompanyDetailsId}`
				);
				const resp = await get(
					`${API_URL.SIGNATORY}?companyId=${selectedCompanyDetailsId}`
				);
				if (response?.data) {
					setTeamData(response?.data?.[0]); // Assuming the API returns data in response.data
				}
				if (resp?.data) {
					setSignatoryData(resp?.data);
				}
			} catch (error) {
				console.error('Error fetching team data:', error);
			}
		},
		[get, selectedCompanyDetails?.id, setTeamData, setSignatoryData]
	);

	const handleSelectCompany = useCallback(
		(company: ICompany) => {
			if (company) {
				setSelectedCompanyDetails(company);
				setIsPopupOpen(false); // Close the popup after selecting
				if (company?.id) {
					fetchTeamData(company?.id);
				}
			}
		},
		[setSelectedCompanyDetails, selectedCompanyDetails?.id, fetchTeamData]
	);

	const handleCreateCompany = () => {
		// Navigate to the company registration page
		navigate('/company-registration');
	};

	const renderCompanyPopup = useMemo(() => {
		return (
			isPopupOpen && (
				<div ref={popupRef}>
					<CompanyListPopup
						items={sortedCompanyList}
						selected={selectedCompanyDetails?.id}
						onSelect={handleSelectCompany}
						onCreateCompany={handleCreateCompany}
					/>
				</div>
			)
		);
	}, [
		isPopupOpen,
		popupRef,
		sortedCompanyList,
		selectedCompanyDetails?.id,
		handleSelectCompany,
		handleCreateCompany,
	]);
	
	return (
		<>
			<header className="header">
				<div className="header__logo">
					<div className="header__logoData">
						{selectedCompanyDetails?.logo ? (
							<img
								src={selectedCompanyDetails?.logo}
								alt="companylogo"
								className="header__logo-img"
							/>
						) : (
							<Logo />
						)}
					</div>
					<div className="header__companyname">
						<div className="header__logoTitle">
							{selectedCompanyDetails?.companyName}
						</div>
						<div className="header__logoArrow" onClick={togglePopup}>
							<i className="ri-arrow-down-s-line"></i>
						</div>
					</div>
				</div>
				<div className="header__line"></div>
				<div className="header__container">
					<div className="header__search">
						<i className="ri-search-line header__search-icon"></i>
						<input
							type="text"
							placeholder="Type a command or search"
							className="header__input"
						/>
						<span className="header__shortcut">⌘ + K</span>
					</div>
					<div className="header__profile">
						<i className="ri-user-line header__profile-icon"></i>
					</div>
				</div>
			</header>
			{renderCompanyPopup}
		</>
	);
};
