import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';
import { Button, Modal, useNotification } from '../../@storybook';

import { API_URL } from '../../constants/api';
import { useNetwork } from '../../hooks';
import { InviteAdministratorHeader } from '../invite-administrator-modal';

import { selectedCompanyState } from '../header';
import { InviteSignatories } from './components';
import {
	isOpenInviteSignatoriesModalState,
	isOpenInviteSignatoriesState,
	memberTitlesState,
} from './store';

import { Loader } from '../../@storybook/loader';
import { isActiveTab } from '../Teams/stores/state';
import { useNavigate } from 'react-router-dom';
import { useSignatory } from './hooks';
import { FC, useEffect } from 'react';
import { getFullName } from './constant';

import './invite-signatories-modal.scss';
import { useTeamData } from '../settings/store/hooks';

interface IInviteSignatoriesModal {
	isFromTeam?: boolean; // Optional property
}

export const InviteSignatoriesModal: FC<IInviteSignatoriesModal> = ({
	isFromTeam = false,
}) => {
	const [isOpenInviteSignatoriesModal, setIsOpenInviteSignatoriesModal] =
		useRecoilState(isOpenInviteSignatoriesModalState);
	const inviteInviteSignatories = useRecoilValue(isOpenInviteSignatoriesState);
	const selectedCompanyDetails = useRecoilValue(selectedCompanyState);
	const resetInviteSignatories = useResetRecoilState(
		isOpenInviteSignatoriesState
	);
	const setActiveTab = useSetRecoilState<string>(isActiveTab);
	const { post, loading, patch } = useNetwork();
	const navigate = useNavigate();
	const { fetchSignatoryData } = useTeamData();
	const { successNotification, errorNotification } = useNotification();
	const { getTitle } = useSignatory();
	const memberTitle = useRecoilValue(memberTitlesState);

	useEffect(() => {
		if (selectedCompanyDetails?.id) {
			getTitle(selectedCompanyDetails?.id);
		}
	}, []);

	// Validation function
	const isFormValid = () => {
		const {
			certificate1FullName,
			certificate1Title,
			certificate2FullName,
			certificate2Title,
			equityPlanFullName,
			convertiblesFullName,
			convertiblesTitle,
			warrantsFullName,
		} = inviteInviteSignatories ?? {};

		return (
			certificate1FullName &&
			certificate1Title &&
			certificate2FullName &&
			certificate2Title &&
			equityPlanFullName &&
			convertiblesFullName &&
			convertiblesTitle &&
			warrantsFullName
		);
	};

	const handleAddSignatory = async () => {
		const {
			certificate1FullName,
			certificate1Title,
			certificate2FullName,
			certificate2Title,
			equityPlanFullName,
			convertiblesFullName,
			convertiblesTitle,
			warrantsFullName,
		} = inviteInviteSignatories ?? {};

		// Check if memberTitle has data (length > 0)
		const isMemberTitleAvailable = memberTitle?.length > 0;

		// Use the type guard for each fullName if memberTitle has data
		const updatedInviteSignatories = {
			certificate1FullName: isMemberTitleAvailable
				? getFullName(certificate1FullName)
				: certificate1FullName,
			certificate2FullName: isMemberTitleAvailable
				? getFullName(certificate2FullName)
				: certificate2FullName,
			equityPlanFullName: isMemberTitleAvailable
				? getFullName(equityPlanFullName)
				: equityPlanFullName,
			convertiblesFullName: isMemberTitleAvailable
				? getFullName(convertiblesFullName)
				: convertiblesFullName,
			warrantsFullName: isMemberTitleAvailable
				? getFullName(warrantsFullName)
				: warrantsFullName,
		};

		const InviteSignatoriesPayload = {
			companyId: selectedCompanyDetails?.id ?? '',
			firstSignatory: {
				name: updatedInviteSignatories.certificate1FullName,
				title: certificate1Title,
			},
			secondSignatory: {
				name: updatedInviteSignatories.certificate2FullName,
				title: certificate2Title,
			},
			equitySignatory: {
				name: updatedInviteSignatories.equityPlanFullName,
			},
			convertableSignatory: {
				name: updatedInviteSignatories.convertiblesFullName,
				title: convertiblesTitle,
			},
			warrantSignatory: {
				name: updatedInviteSignatories.warrantsFullName,
			},
		};

		if (isFromTeam) {
			const response = await patch(
				`${API_URL.SIGNATORY}/${selectedCompanyDetails?.id}`,
				InviteSignatoriesPayload
			);
			if (response?.data && selectedCompanyDetails?.id) {
				successNotification('Signatories updated successfully!');
				setIsOpenInviteSignatoriesModal(false);
				resetInviteSignatories();
				fetchSignatoryData(selectedCompanyDetails?.id);
			} else {
				errorNotification(response?.message ?? 'Something went wrong !');
			}
		} else {
			const resp = await post(API_URL.SIGNATORY, InviteSignatoriesPayload);
			if (resp?.data) {
				successNotification('Signatories added successfully!');
				setIsOpenInviteSignatoriesModal(false);
				resetInviteSignatories();
				setActiveTab('Signatories');
				navigate('/settings');
			} else {
				errorNotification(resp?.message ?? 'Something went wrong !');
			}
		}
	};

	const handleCloseModal = () => {
		setIsOpenInviteSignatoriesModal(false);
		resetInviteSignatories();
	};

	return (
		<div className="InviteSignatoriesModal">
			<Modal
				showHeader={true}
				isOpen={isOpenInviteSignatoriesModal}
				modalName="InviteSignatoriesModal Modal"
				className="InviteSignatoriesModal--modal"
				closeModal={handleCloseModal}
				showCloseBtn={true}
				isStopOutsideClick={true}
				title={
					<InviteAdministratorHeader
						title="Signatories"
						subTitle="Add Signatories"
					/>
				}
			>
				<div className="InviteSignatoriesModal--body">
					<InviteSignatories />
					<div className="InviteSignatoriesModal--body__btn">
						<Button
							label={loading ? <Loader dimension={18} /> : 'Add signatory'}
							handleClick={handleAddSignatory}
							type="button__filled button__filled--primary button__large"
							disabled={!isFormValid()}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
};
