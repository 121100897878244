import { IButton } from '.';

import { FC } from 'react';

import './button.scss';

export const Button: FC<IButton> = ({
	label,
	handleClick,
	loader,
	type,
	icon,
	height,
	disabled,
}) => {
	return (
		<button
			className={`button ${type} ${disabled ? 'button--disabled' : ''}`}
			onClick={handleClick}
			style={{ height }}
			disabled={disabled}
		>
			{icon && <i className={icon} />}
			{label}
			{loader}
		</button>
	);
};
