import { atom } from 'recoil';
import { ICompany } from './store/type';

export const formState = atom({
	key: 'form-state',
	default: {},
});

export const selectedCompany = atom<ICompany[]>({
	key: 'selected-company',
	default: [],
});