import { atom } from 'recoil';
import { IInviteBoardMemberState } from './types';

// Atom for modal open state
export const IsOpenInviteBoardMemberModal = atom<boolean>({
  key: 'is-open-invite-board-member-modal',
  default: false,
});

// Atom for invite board member state
export const InviteBoardMemberState = atom<IInviteBoardMemberState>({
  key: 'invite-board-member-state',
  default: {
    firstName: '',
    lastName: '',
    email: '',
    boardRole: [],
    startDate: '',
    endDate: '',
    countryCode:'+1',
		phoneNumber:''
  },
});