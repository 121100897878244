import { ChangeEvent } from 'react';

import './text-area.scss';

interface Props {
	label: string;
	handleChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
	value: string;
	placeholder: string;
	isError?: boolean;
	row?: number;
	disable?: boolean;
	inputName?: string;
	errorMessage?: string;
}
export const TextArea = ({
	label,
	handleChange,
	value,
	placeholder,
	isError = false,
	row = 6,
	disable = false,
	inputName = 'some area',
	errorMessage,
}: Props) => {
	return (
		<div className="input">
			{label && <div className="input__label textarea-label">{label}</div>}
			<div
				className={`input__text-field input__text-field${
					isError ? '--error' : ''
				}`}
			>
				<textarea
					name={inputName}
					className="input__field text-area-field"
					rows={row}
					value={value}
					placeholder={placeholder}
					onChange={handleChange}
					disabled={disable}
				/>
			</div>
			{isError && errorMessage && (
				<span className="input__error">{errorMessage}</span>
			)}
		</div>
	);
};
