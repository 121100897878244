export const SIDEBAR_ITEMS = [
    { id: "company", label: "Company", icon: "ri-building-line" },
    { id: "team", label: "Team", icon: "ri-group-2-line" },
    { id: "roles", label: "Roles", icon: "ri-shield-user-line" },
    {
      label: "Account",
      children: [
        { id: "profile", label: "Profile", icon: "ri-account-circle-line" },
        { id: "notification", label: "Notification", icon: "ri-notification-2-line" },
        { id: "security", label: "Security", icon: "ri-lock-2-line" },
      ],
    },
  ];
  