import React from 'react';
import './signatory-form.scss';
import { Input, ReactDropdown } from '../../../../@storybook';

export const SignatoryForm: React.FC<any> = ({
	sections,
	handleChange,
	handleChangeSelect,
	errors = {},
	formData = {},
	getCustomOptions,
}: any) => {
	return (
		<div className="SignatoryForm">
			{sections.map((section: any, index: any) => (
				<div key={index} className="SignatoryForm__section">
					<div className="SignatoryForm__title">{section.section}</div>
					<div className="SignatoryForm__input-container">
						{section.fields.map((field: any, idx: any) => {
							const {
								label,
								placeholder,
								name = '',
								type='',
								options = [],
								isMulti = false,
								isRequired = false,
								isDisabled = false,
							} = field ?? {};
							return (
								<div
									key={idx}
									className="SignatoryForm__input-form"
									style={{ width: `${field.width}%` }}
								>
									{field.type === 'text' ? (
										<Input
											label={label}
											placeholder={placeholder}
											handleChange={handleChange}
											inputType={type}
											isRequired={isRequired}
											inputName={name}
											value={formData[name] || ''}
											isError={!!errors?.[name]} // Check for error
											errorMessage={errors?.[name]} // Pass error message
										/>
									) : field.type === 'multi-select' && field.options ? (
										<>
											<ReactDropdown
												name={name}
												isRequired={isRequired}
												label={label}
												options={options || []}
												isCustomComponent={false}
												placeholder={
													name === 'permissions' ? (
														<div
															style={{ display: 'flex', alignItems: 'center' }}
														>
															<i
																className="ri-search-line"
																style={{ marginRight: '8px', color: '#9CA3AF' }}
															/>
															{placeholder}
														</div>
													) : (
														placeholder
													)
												}
												handleChangeSelect={(selectedOption: any) =>
													handleChangeSelect(selectedOption, name)
												}
												getCustomOptions={getCustomOptions}
												isMulti={isMulti}
												errorMessage={errors[name]}
												value={formData[name]}
												Isdisable={isDisabled}
												isSearchable
											/>
										</>
									) : null}
								</div>
							);
						})}
					</div>
				</div>
			))}
		</div>
	);
};
