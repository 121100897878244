import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';
import { App } from './App';


import 'react-toastify/dist/ReactToastify.css';
import 'remixicon/fonts/remixicon.css';
import 'tippy.js/dist/tippy.css';

import './index.scss';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<RecoilRoot>
		<BrowserRouter>
			<App />
		</BrowserRouter>
		<ToastContainer
			position="top-center"
			autoClose={2000}
			hideProgressBar
			closeOnClick
			pauseOnHover
			theme="dark"
			limit={1}
		/>
	</RecoilRoot>
);