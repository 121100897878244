import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Teams } from '../Teams';
import { selectedCompanyState } from '../header';
import { FeatureUnderDeveploment } from '../hooks';
import { SettingSidebar } from './components';
import { SIDEBAR_ITEMS } from './constant';
import { useTeamData } from './store/hooks';

import './settings.scss';

export const Settings: React.FC = () => {
	const [activeScreen, setActiveScreen] = useState('team');
	const { fetchTeamData ,fetchSignatoryData} = useTeamData();
	const selectedCompanyDetails = useRecoilValue(selectedCompanyState);

	// Fetch data when activeScreen is "team"
	useEffect(() => {
		if (activeScreen === 'team' && selectedCompanyDetails?.id) {
			fetchTeamData(selectedCompanyDetails?.id);
			fetchSignatoryData(selectedCompanyDetails?.id)
		}
	}, [activeScreen]);

	const renderScreen = useMemo(() => {
		switch (activeScreen) {
			case 'company':
				return <FeatureUnderDeveploment />;
			case 'team':
				return <Teams />;
			case 'roles':
				return <FeatureUnderDeveploment />;
			case 'profile':
				return <FeatureUnderDeveploment />;
			case 'notification':
				return <FeatureUnderDeveploment />;
			case 'security':
				return <FeatureUnderDeveploment />;
			default:
				return <FeatureUnderDeveploment />;
		}
	}, [activeScreen]);

	return (
		<div className="Settings">
			<SettingSidebar
				items={SIDEBAR_ITEMS}
				activeItem={activeScreen}
				onItemSelect={setActiveScreen}
			/>
			<main className="Settings__content">{renderScreen}</main>
		</div>
	);
};
