export const LawFirmInformationFormDetails = [
	{
		title: 'Law Firm Information',
		fields: [
			{
				label: 'First name',
				name: 'firstName',
				type: 'text',
				placeholder: 'Enter first name',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Last name',
				name: 'lastName',
				type: 'text',
				placeholder: 'Enter last name',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Email',
				name: 'email',
				type: 'text',
				placeholder: 'Enter email',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Phone Number',
				name: 'phoneNumber',
				type: 'phoneNumber',
				placeholder: 'Enter phone number',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Law firm name',
				name: 'lawFirmName',
				type: 'dropdown',
				placeholder: 'Select law firm name',
				isRequired: true,
				width: 100,
				options: [
					{
						label: 'Admin',
						value: 'admin',
						number: 0,
					},
					{
						label: 'Manager',
						value: 'manager',
						number: 1,
					},
					{
						label: 'User',
						value: 'user',
						number: 2,
					},
				],
			},
		],
	},
];
