import React from 'react';

import './company-setup-details-card.scss';

interface Task {
	title: string;
	description: string;
	buttonText: string;
	showRadio: boolean;
	companyInviteType: string;
}

interface Props {
	task: Task;
	onButtonClick: (taskTitle: string) => void;
	onRadioChange: (taskTitle: string) => void;
	isSelected: boolean;
	isDisabled?:boolean
}

export const CompanySetupDetailsCard: React.FC<Props> = ({
	task,
	onButtonClick,
	onRadioChange,
	isSelected,
	isDisabled = false
}) => {
	return (
		<div className="CompanySetupDetailsCard">
			<div className="CompanySetupDetailsCard__content">
				<div className="CompanySetupDetailsCard__radio">
					{task.showRadio && (
						<input
							type="radio"
							name="taskRadio"
							checked={isSelected}
							onChange={() => onRadioChange(task.title)}
							className="CompanySetupDetailsCard__task-radio"
						/>
					)}
					<label
						htmlFor="taskRadio"
						className={`${isSelected ? 'checked' : ''}`}
					>
						<i
							className={`ri-checkbox-circle-line ${isSelected ? 'selected' : 'not-selected'}`}
						></i>
					</label>
				</div>
				<div className="CompanySetupDetailsCard__label">
					<div className="CompanySetupDetailsCard__title">{task.title}</div>
					<p className="CompanySetupDetailsCard__description">
						{task.description}
					</p>
				</div>
			</div>
			<div
				className={`CompanySetupDetailsCard__button ${isDisabled ?'CompanySetupDetailsCard__isDisabled' :''}`}
				onClick={() => onButtonClick(task.companyInviteType)}
			>
				{task.buttonText}
			</div>
		</div>
	);
};
