import { ReactNode, KeyboardEvent } from 'react';

import classNames from 'classnames';
import ReactModal, { Styles } from 'react-modal';

import './modal.scss';

type Props = {
	className?: string;
	isOpen: boolean;
	closeModal?: () => void;
	overlayStyles?: Styles;
	modalName: string;
	children: ReactNode;
	showCloseBtn?: boolean;
	title?: string | JSX.Element;
	isStopOutsideClick?: boolean;
	closeOnEscBtn?: boolean;
	ariaHideApp?: any;
	optionalClassName?: string;
	showHeader?: boolean;
};

export const styles = {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(44, 48, 57, 0.8)',
		zIndex: 100,
		overflowY: 'auto',
	},
};

export const Modal = ({
	className,
	isOpen,
	closeModal = () => ({}),
	overlayStyles,
	modalName,
	children,
	showCloseBtn = true,
	title,
	isStopOutsideClick = true,
	closeOnEscBtn = true,
	optionalClassName,
	showHeader = true,
}: Props) => {
	const classes = classNames('Modal', className);

	const handleClose = (event: KeyboardEvent<HTMLDivElement>): void => {
		if (event.key === 'Enter') {
			closeModal();
		}
	};

	const combinedStyles = {
		...styles,
		overlay: { ...styles.overlay, ...overlayStyles?.overlay },
	};

	return (
		<ReactModal
			closeTimeoutMS={500}
			isOpen={isOpen}
			onRequestClose={closeModal}
			contentLabel={modalName}
			style={combinedStyles as Styles}
			className={classes}
			shouldCloseOnOverlayClick={isStopOutsideClick}
			shouldCloseOnEsc={closeOnEscBtn}
			parentSelector={() => document.querySelector('body') as any}
			ariaHideApp={false}
		>
			<div
				className={`Modal__wrapper ${
					optionalClassName ? optionalClassName : ''
				}`}
			>
				<div style={{ display: showHeader ? 'contents' : 'none' }}>
					<div>{title}</div>
					{showCloseBtn && (
						<div
							className="Modal__closeButton"
							onClick={closeModal}
							onKeyPress={handleClose}
							role="button"
							tabIndex={0}
						>
							<div className="Modal__closeIcon">ESC</div>
						</div>
					)}
				</div>
				{children}
			</div>
		</ReactModal>
	);
};
