import { atom } from "recoil";
import { ICompany } from "../../company-registration-form/store";

export const selectedCompanyState = atom<ICompany | null>({
    key: 'selectedCompanyState',
    default: null,
  });

export const companyInviteTypeState = atom<string>({
    key: 'companyInviteTypeState',
    default: '',
  });