import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CompanySetupSection } from './components';
import { companySetupSections } from './constant';

import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { InviteAdministratorModal } from '../invite-administrator-modal';
import { IsOpenInviteAdministratorModal } from '../invite-administrator-modal/store';
import { InviteBoardMemberModal } from '../invite-board-member-modal/invite-board-member-modal';
import { IsOpenInviteBoardMemberModal } from '../invite-board-member-modal/store';
import { InviteLegalContactModal } from '../invite-legal-contact-modal/invite-legal-contact-modal';
import { IsOpenInviteLegalContactModal } from '../invite-legal-contact-modal/store';
import { InviteSignatoriesModal } from '../invite-signatories-modal';
import { isOpenInviteSignatoriesModalState } from '../invite-signatories-modal/store';
import { formState } from '../company-registration-form/state';
import { companyInviteTypeState, selectedCompanyState } from '../header';
import {
	IAddress,
	IIncorporationDetails,
} from '../company-registration-form/store';
import { useWaringNotification } from '../hooks';
import { useSignatory } from '../invite-signatories-modal/hooks';
import { signatoryDataState } from '../settings/store';

import './company-setup.scss';

export const CompanySetup: React.FC = () => {
	const [selectedRadio, setSelectedRadio] = useState<string | null>(null);
	const [isOpenInviteAdministratorModal, setIsOpenInviteAdministratorModal] =
		useRecoilState(IsOpenInviteAdministratorModal);
	const [isOpenInviteBoardMemberModal, setIsOpenInviteBoardMemberModal] =
		useRecoilState(IsOpenInviteBoardMemberModal);
	const [isOpenInviteLegalContactModal, setIsOpenInviteLegalContactModal] =
		useRecoilState(IsOpenInviteLegalContactModal);
	const [isOpenInviteSignatoriesModal, setIsOpenInviteSignatoriesModal] =
		useRecoilState(isOpenInviteSignatoriesModalState);
	const selectedCompanyDetails = useRecoilValue(selectedCompanyState);
	const setFormData = useSetRecoilState(formState);
	const setCompanyInviteTypeState = useSetRecoilState(companyInviteTypeState);
	const signatoryData = useRecoilValue(signatoryDataState); 
	const navigate = useNavigate(); // Hook for navigation
	const { showUnderDevlopment } = useWaringNotification();
	const { getTitle } = useSignatory();

	useEffect(() => {
		if (selectedCompanyDetails?.id) {
			getTitle(selectedCompanyDetails?.id);
		}
	}, []);

	const memoizedFormData = useMemo(() => {
		const {
			companyName,
			taxId,
			accountant,
			tcc,
			industrySector,
			stateOfFormation,
			dateOfFormation,
			dba,
			jobTitle,
			website,
			businessDescription,
			logo,
			incorporationDetails = {},
			address = {},
		} = selectedCompanyDetails || {};

		const {
			type: incorporationType,
			state: incorporationState,
			country: incorporationCountry,
			date: incorporationDate,
		} = incorporationDetails as IIncorporationDetails;

		const {
			city,
			zip: zipCode,
			street: streetAddress,
			state,
			country,
		} = address as IAddress;

		return {
			companyName,
			taxId,
			accountant,
			tcc,
			industrySector,
			stateOfFormation,
			dateOfFormation,
			dba,
			jobTitle,
			website,
			businessDescription,
			logo,
			incorporationType,
			incorporationState,
			incorporationDate,
			incorporationCountry,
			city,
			zipCode,
			streetAddress,
			state,
			country,
		};
	}, [selectedCompanyDetails]);

	// Handle button click
	const handleButtonClick = useCallback(
		(companyInviteType: string) => {
			switch (companyInviteType) {
				case 'administrators':
					setIsOpenInviteAdministratorModal(true);
					break;
				case 'boardMember':
					setIsOpenInviteBoardMemberModal(true);
					break;
				case 'companyProfile':
					setCompanyInviteTypeState(companyInviteType ?? '');
					setFormData(memoizedFormData);
					navigate('/company-registration');
					break;
				case 'legalContact':
					setIsOpenInviteLegalContactModal(true);
					break;
				case 'rolesSignatories':
					if(!signatoryData?.length){
						setIsOpenInviteSignatoriesModal(true);
					}
					break;
				case 'uploadDocument':
					showUnderDevlopment();
					break;
				case 'equityPlan':
					showUnderDevlopment();
					break;
				case 'stakeholderDetails':
					showUnderDevlopment();
					break;
				case 'verifyRights':
					showUnderDevlopment();
					break;
				case 'verifyCapTable':
					showUnderDevlopment();
					break;
				default:
					console.warn('Unknown company invite type:', companyInviteType);
			}
		},
		[memoizedFormData, navigate, setCompanyInviteTypeState,signatoryData?.length] // Add all external dependencies here
	);

	// Handle radio button change
	const handleRadioChange = (taskTitle: string) => {
		setSelectedRadio(taskTitle);
	};

	const isInviteDisabled = useMemo(()=>{
		if(signatoryData?.length)return true;
		return false
	},[signatoryData?.length])


	return (
		<div className="CompanySetup">
			{companySetupSections.map((section: any, index: any) => (
				<CompanySetupSection
					key={index}
					sectionTitle={section.sectionTitle}
					tasks={section.tasks}
					onButtonClick={handleButtonClick}
					onRadioChange={handleRadioChange}
					selectedRadio={selectedRadio}
					isDisabled = {isInviteDisabled}
				/>
			))}
			{isOpenInviteAdministratorModal && <InviteAdministratorModal />}
			{isOpenInviteBoardMemberModal && <InviteBoardMemberModal />}
			{isOpenInviteLegalContactModal && <InviteLegalContactModal />}
			{isOpenInviteSignatoriesModal && <InviteSignatoriesModal/>}
		</div>
	);
};
