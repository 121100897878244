import React, { useState, useEffect } from "react";
import TabNavigation from "../tab-navigation/tab-navigation";
import { ReactResponsiveTable } from "../../../react-responsive-table";
import {
	roleDataState,
	signatoryDataState,
} from "../../../settings/store/state";
import { useRecoilState, useRecoilValue } from "recoil";
import { isActiveTab } from "../../stores/state";
import { formatDate } from "../../../../utils";
import { userRoleState } from "../../../../hooks";

import "./teams-body-section.scss";

export const TeamsBodySection: React.FC = () => {
	const [activeTab, setActiveTab] = useRecoilState<string>(isActiveTab);
	const [tableData, setTableData] = useState<any[]>([]);
	const [columns, setColumns] = useState<any[]>([]);
	const roles = useRecoilValue(userRoleState);
	// Recoil state
	const teamData = useRecoilValue(roleDataState);
	const signatoryData = useRecoilValue(signatoryDataState);
	const getSignatoriesData = (signatories: any[] = []) => {
		const {
			firstSignatory = {},
			secondSignatory = {},
			warrantSignatory,
			convertableSignatory = {},
			equitySignatory = "",
			updatedAt = "",
			id = "",
		} = signatories[signatories?.length - 1] ?? {}; // Use safe access in case array is empty

		return [
			{
				document: "Certificate (1st Signatory)",
				signatory: firstSignatory?.name,
				updatedAt,
				title: firstSignatory?.title,
				id,
			},
			{
				document: "Certificate (2nd Signatory)", // Fixed typo '2st' to '2nd'
				signatory: secondSignatory?.name,
				updatedAt,
				title: secondSignatory?.title,
				id,
			},
			{
				document: "Equity plan awards",
				signatory: equitySignatory?.name,
				updatedAt,
				id,
			},
			{
				document: "Convertibles",
				signatory: convertableSignatory?.name,
				updatedAt,
				title: convertableSignatory?.title, // Fixed to use 'convertableSignatory' for title
				id,
			},
			{
				document: "Warrants",
				signatory: warrantSignatory?.name,
				updatedAt,
				id,
			},
		];
	};

	useEffect(() => {
		if (signatoryData?.length) {
			getSignatoriesData(signatoryData);
		}
	}, [signatoryData]);


	const getAccessById = (id: string): string => {
		const role = (roles ?? [])?.find((role) => role?.id === id);
		if (!role) return "NA"; // Return a message if the ID is invalid

		switch (role.name) {
			case "Admin":
				return "Full Access";
			case "Manager":
				return "Edit Access Only";
			case "User":
				return "View Only";
			default:
				return "No Access";
		}
	};

	const getColumnsForTab = (tab: string) => {

		switch (tab) {
			case "LawFirm":
				return [
					{ key: "fullName", label: "Full Name" },
					{ key: "email", label: "Email" },
					{ key: "lawFirmName", label: "Law Firm Name" },
				];
			case "Signatories":
				return [
					{ key: "document", label: "Document" },
					{ key: "signatory", label: "Signatory" },
					{ key: "dateAssigned", label: "Date Assigned" },
				];
			case "Administration":
				return [
					{ key: "fullName", label: "Full Name" },
					{ key: "email", label: "Email" },
					{ key: "title", label: "Title" },
					{ key: "permission", label: "Permissions" },
				];
			case "BoardMember":
				return [
					{ key: "fullName", label: "Full Name" },
					{ key: "email", label: "Email" },
					{ key: "role", label: "Role" },
					{ key: "startDate", label: "Start Date" },
					{ key: "endDate", label: "End Date" },
				];
			default:
				return [];
		}
	};

	const getDataForTab = (tab: string) => {
		switch (tab) {
			case "LawFirm":
				return (
					teamData?.LawForm?.map((person) => ({
						fullName: `${person?.firstName} ${person?.lastName}`,
						email: person?.email,
						lawFirmName: person?.lawFirmName,
					})) || []
				); // Fallback to an empty array if undefined
			case "BoardMember":
				return (
					teamData?.BoardMember?.map((person) => ({
						fullName: `${person?.firstName} ${person?.lastName}`,
						email: person?.email,
						role: person.role,
						startDate: formatDate(person?.createdAt),
						endDate: formatDate(person?.updatedAt),
					})) || []
				); // Fallback to an empty array if undefined
			case "Administration":
				return (
					teamData?.Administration?.map((person) => ({
						fullName: `${person.firstName} ${person?.lastName}`,
						email: person?.email,
						title: person?.title,
						permission: getAccessById(person?.roleId),
					})) || []
				); // Fallback to an empty array if undefined
			case "Signatories":
				if (signatoryData?.length) {
					return (
						getSignatoriesData(signatoryData)?.map((person) => ({
							document: person?.document,
							signatory: person?.signatory,
							dateAssigned: formatDate(person?.updatedAt),
						})) || []
					);
				} else return [];

			default:
				return [];
		}
	};

	// Dynamically set the columns based on the active tab
	useEffect(() => {
		setColumns(getColumnsForTab(activeTab));
		setTableData(getDataForTab(activeTab));
	}, [activeTab, teamData]);

	// Handle the tab change
	const handleTabChange = (tab: string) => {
		setActiveTab(tab);
	};

	// Handle row action (edit or delete)
	const handleRowAction = (action: "edit" | "delete", rowId: number) => {
		console.log(`${action} row with id: ${rowId}`);
		// Implement edit or delete logic here
	};

	return (
		<div className="law-firm-management">
			<TabNavigation activeTab={activeTab} onTabChange={handleTabChange} />
			<ReactResponsiveTable
				columns={columns}
				data={tableData}
				isLoading={false}
				onRowAction={handleRowAction}
				isPagination={false}
			/>
		</div>
	);
};
