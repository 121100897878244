export const BoardMemberInformationFormDetails = [
	{
		title: 'Member Information',
		fields: [
			{
				label: 'First name',
				name: 'firstName',
				type: 'text',
				placeholder: 'Enter first name',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Last name',
				name: 'lastName',
				type: 'text',
				placeholder: 'Enter last name',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Email',
				name: 'email',
				type: 'text',
				placeholder: 'Enter email',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Phone Number',
				name: 'phoneNumber',
				type: 'phoneNumber',
				placeholder: 'Enter phone number',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Board role',
				name: 'boardRole',
				description:
					"Board members will be added to account users and will have access to view the company's cap table. You can modify their level by visiting Account users.",
				type: 'dropdown',
				placeholder: 'Enter board role',
				isRequired: true,
				width: 100,
				options: [
					{
						label: 'Director',
						value: 'User',
						number: 0,
					},
					{
						label: 'Observer',
						value: 'User',
						number: 1,
					},
				],
			},
			{
				label: 'Start date',
				name: 'startDate',
				description: "Start of this person's tenure in the board role.",
				type: 'date',
				placeholder: 'Select start date',
				isRequired: true,
				width: 50,
			},
			{
				label: 'End date',
				name: 'endDate',
				description: "End of this person's tenure in the board role.",
				type: 'date',
				placeholder: 'Select end date',
				isRequired: false,
				width: 50,
			},
		],
	},
];
