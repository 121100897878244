import React from 'react';
import './setting-sidebar.scss';

interface SidebarItem {
	id?: string;
	label: string;
	icon?: string;
	children?: SidebarItem[];
}

interface SidebarProps {
	items: SidebarItem[];
	activeItem: string;
	onItemSelect: (id: string) => void;
}

export const SettingSidebar: React.FC<SidebarProps> = ({
	items,
	activeItem,
	onItemSelect,
}) => {
	return (
		<aside className="SettingSidebar">
			<div className="SettingSidebar-title">Settings</div>
			<ul className="SettingSidebar-list">
				{items.map((item) => (
					<li key={item.label} className="SettingSidebar-section">
						{item.children ? (
							<>
								<div className="SettingSidebar-section-title">{item.label}</div>
								<ul className="SettingSidebar-sublist">
									{item.children.map((child) => (
										<li
											key={child.id}
											className={`SettingSidebar-item ${activeItem === child.id ? 'active' : ''}`}
											onClick={() => child.id && onItemSelect(child.id)}
										>
											{child.icon && <i className={child.icon} />}
											{child.label}
										</li>
									))}
								</ul>
							</>
						) : (
							<div
								className={`SettingSidebar-item ${activeItem === item.id ? 'active' : ''}`}
								onClick={() => item.id && onItemSelect(item.id)}
							>
								{item.icon && <i className={item.icon} />}
								{item.label}
							</div>
						)}
					</li>
				))}
			</ul>
		</aside>
	);
};
