export const removeEmptyValues = <T extends Record<string, any>>(
	data: T
): T => {
	return Object.fromEntries(
		Object.entries(data)
			.filter(([key, value]) => {
				// Preserve the 'logo' field, even if it's null or undefined
				if (key === 'logo') return true;
				return value !== null && value !== undefined;
			})
			.map(([key, value]) => [
				key,
				value && typeof value === 'object' && !Array.isArray(value) // If it's an object, clean recursively
					? removeEmptyValues(value) // Recursively call for nested objects
					: value,
			])
	) as T; // Ensure the return type matches the original type
};