import { useNotification } from "../../@storybook";

// Custom hook to show error notification for an unavailable feature
export const useWaringNotification = () => {
  const { warningNotification } = useNotification(); // Use the existing notification hook

  const showUnderDevlopment = () => {
    warningNotification("This feature is currently under development and will be available soon.");
  };

  return { showUnderDevlopment };
};