/**
 * Utility function to format a date into MM/DD/YYYY
 * @param date - A Date object or a valid date string/timestamp
 * @returns The formatted date string in MM/DD/YYYY format
 */
export const formatDate = (date: Date | string | number): string => {
    const parsedDate = new Date(date);
  
    if (isNaN(parsedDate.getTime())) {
      return 'NA'
    }
  
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");
    const year = parsedDate.getFullYear();
  
    return `${month}/${day}/${year}`;
  };