import React, { useState, useEffect, ChangeEvent } from 'react';
import { Input, ReactDropdown } from '../../@storybook';
import { DropInfoOptions } from './components';

import './react-responsive-table.scss';
import { selectedCompanyState } from '../header';
import { useRecoilValue } from 'recoil';

interface TableProps {
	columns: { key: string; label: string }[];
	data: any[];
	isLoading: boolean;
	onRowAction: (action: 'edit' | 'delete', rowId: number) => void;
	isPagination?: boolean;
}

export const ReactResponsiveTable: React.FC<TableProps> = ({
	columns,
	data,
	isLoading,
	onRowAction,
	isPagination = false,
}) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [selectedColumns, setSelectedColumns] = useState<string[]>(
		columns?.map((col) => col?.key)
	);
	const selectedCompanyDetails = useRecoilValue(selectedCompanyState);
	const [filteredData, setFilteredData] = useState(data);

	useEffect(() => {
		setSelectedColumns(columns.map((col) => col.key));
	}, [columns]);

	useEffect(() => {
		const lowercasedFilter = searchTerm.toLowerCase();
		const filtered = data?.filter((item) => {
			return Object.keys(item)?.some((key) =>
				item[key]?.toString()?.toLowerCase()?.includes(lowercasedFilter)
			);
		});
		setFilteredData(filtered);
	}, [data, searchTerm]);

	const handleColumnSelect = (selectedOptions: any) => {
		if (selectedOptions.some((opt: any) => opt.value === 'all')) {
			setSelectedColumns(columns.map((col) => col.key));
		} else {
			setSelectedColumns(selectedOptions.map((opt: any) => opt.value));
		}
	};

	if (isLoading) {
		return <div className="loading-container">Loading...</div>;
	}

	const columnOptions = [
		{ value: 'all', label: 'All Columns' },
		...columns.map((col) => ({ value: col.key, label: col.label })),
	];
	return (
		<div className="table-container">
			<div className="table-controls">
				<div className="search-box">
					<Input
						inputType="text"
						placeholder="Search table..."
						value={searchTerm}
						handleChange={(e: ChangeEvent<HTMLInputElement>) =>
							setSearchTerm(e.target.value)
						}
						className="search-input"
					/>
				</div>
				<div className="select-box">
					<ReactDropdown
						options={columnOptions}
						isMulti={true}
						value={columnOptions.filter((opt) =>
							selectedColumns.includes(opt.value)
						)}
						handleChangeSelect={handleColumnSelect}
						placeholder="Select columns"
						getCustomOptions={undefined}
					/>
				</div>
			</div>

			<div className="table-wrapper">
				{selectedCompanyDetails?.companyName && (
					<table className="table">
						<thead>
							<tr>
								<th>
									{selectedColumns.length ? (
										<input type="checkbox" style={{ visibility: 'hidden' }} />
									) : (
										''
									)}
								</th>
								{columns
									?.filter((col) => selectedColumns.includes(col?.key))
									?.map((column) => <th key={column.key}>{column.label}</th>)}
								<th></th> {/* <th>Actions</th> */}
							</tr>
						</thead>
						<tbody>
							{selectedColumns.length === 0 ? (
								<tr>
									<td
										colSpan={columns.length + 2}
										className="table-wrapper__no-results"
									>
										No columns selected. Please select at least one column to
										display table data.
									</td>
								</tr>
							) : filteredData.length === 0 ? (
								<tr>
									<td
										colSpan={columns.length + 2}
										className="table-wrapper__no-results"
									>
										No results found.
									</td>
								</tr>
							) : (
								filteredData.map((row) => (
									<tr key={row.id}>
										<td>
											<input type="checkbox" style={{ visibility: 'hidden' }} />
										</td>
										{columns
											.filter((col) => selectedColumns.includes(col.key))
											.map((column) => (
												<td key={column.key}>{row[column.key] ?? '--'}</td>
											))}
										<td>
											<DropInfoOptions
												handleEdit={() => onRowAction('edit', row.id)}
												handleDelete={() => onRowAction('delete', row.id)}
												editLabel="Edit"
												deleteLabel="Delete"
												isDisabled={true}
											/>
										</td>
									</tr>
								))
							)}
						</tbody>
					</table>
				)}
			</div>

			{isPagination && (
				<div className="pagination">
					<span className="pagination__selected--row">
						{filteredData.length} of {data.length} rows
					</span>
					<div className="pagination__btn">
						<div className="pagination__btn--next">Previous</div>
						<div className="pagination__btn--next">Next</div>
					</div>
				</div>
			)}
		</div>
	);
};