import React from 'react';

import './logo.scss';

export const Logo: React.FC = () => {
  return (
    <div className="logo--container">
      <i className="ri-pie-chart-fill logo-icon"></i>
    </div>
  );
};