import { atom } from "recoil";

export interface Person {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	companyId: string;
	roleId: string;
	createdAt: string;
	updatedAt: string;
	lawFirmName: string | null;
	role: string;
	title?: string | null;
}

export interface RoleData {
	LawFirm?: Person[];
	Manager?: Person[];
	Administration?: Person[];
	Signatories?: Person[];
	BoardMember?: Person[];
	LawForm?: Person[];
}

// types.ts
export interface Signatory {
	name: string;
	roleId?: string;
	title?: string;
}

export interface SignatoryInfo {
	firstSignatory: Signatory;
	secondSignatory: Signatory;
	equitySignatory: Signatory | null;
	convertableSignatory: Signatory;
	warrantSignatory: Signatory;
}

export interface SignatoryData extends SignatoryInfo {
	id: string;
	companyId: string;
	createdAt: string;
	updatedAt: string;
}	

// Recoil state
export const roleDataState = atom<RoleData>({
	key: "role-data-state", // Unique key for this atom
	default: {
		LawFirm: [],
		Manager: [],
		Administration: [],
		Signatories: [],
		BoardMember: [],
		LawForm: [],
	},
});

export const signatoryDataState = atom<SignatoryData[]>({
	key: "signatoryDataState", // unique ID (with respect to other atoms/selectors)
	default: [], // initial state
});
