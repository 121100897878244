import React, { FC } from 'react';
import './teams-section-wrapper.scss';
import { Button } from '../../../../@storybook';

interface ITeamsSectionWrapper {
	headerTitle: string;
	buttonLabel: string;
	onButtonClick: () => void;
	children?: React.ReactNode;
}

export const TeamsSectionWrapper: FC<ITeamsSectionWrapper> = ({
	headerTitle,
	buttonLabel,
	onButtonClick,
	children,
}) => {
	return (
		<div className="TeamsSectionWrapper--container">
			<div className="TeamsSectionWrapper--header">
				<h1>{headerTitle}</h1>
				<div className='TeamsSectionWrapper__btn-container'>
				<Button
					label={buttonLabel}
					handleClick={onButtonClick}
					type="button__filled button__filled--primary button__medium TeamsSectionWrapper--button"
					icon='ri-add-fill'
				/>
				</div>
			</div>
			<div className="TeamsSectionWrapper--content">{children}</div>
		</div>
	);
};
