import { useRecoilState, useRecoilValue } from 'recoil';
import { SignatoriesFormFields } from '../../constant';
import { SignatoryForm } from '../signatory-form';
import './invite-signatories.scss';
import { useCallback, useState } from 'react';
import { isOpenInviteSignatoriesState, memberTitlesState } from '../../store';
import { ISelectOption } from '../../../invite-administrator-modal';
import { containsDigit } from '../../../../utils';

export const InviteSignatories = () => {
	const [inviteSignatories, setInviteSignatoriesState] = useRecoilState(
		isOpenInviteSignatoriesState
	);
	const memberTitle = useRecoilValue(memberTitlesState);

	const [errors, setErrors] = useState({});
	// Prepare dropdown options based on member titles
	const dropdownOptions = memberTitle?.map((member,index) => ({
		label: member?.name,
		value: member?.name,
		number: index
	}));

	// Create updated sections without mutating the original SignatoriesFormFields
	const updatedSections = SignatoriesFormFields.sections.map((section) => ({
		...section,
		fields: section.fields.map((field) => {
			if (field.label === 'Full name') {
				return {
					...field,
					type: 'multi-select', // Change type to dropdown
					options: dropdownOptions, // Add options
				};
			}
			return field;
		}),
	}));

	const handleChangeSelect = (
		selectedOption: { label: string; value: string },
		name: string
	  ) => {
		// Find the section that contains the selected full name field
		const section = SignatoriesFormFields.sections.find((section) =>
		  section.fields.some((field) => field.name === name)
		);
	  
		// Check if the section exists and has a corresponding title field
		if (section) {
		  // Find the corresponding member title based on the selected full name
		  const selectedMember = memberTitle.find(
			(member) => member.name === selectedOption.label
		  );
	  
		  // If a corresponding member is found, get the title
		  const title = selectedMember ? selectedMember.title : '';
	  
		  // Find the title field for the section
		  const titleField = section.fields.find(
			(field) => field.name === name.replace('FullName', 'Title')
		  );
	  
		  // If a corresponding title field exists, prefill it with the selected member's title
		  if (titleField) {
			setInviteSignatoriesState((prevData) => ({
			  ...prevData,
			  [name]: [selectedOption], // Update the full name field
			  [titleField.name]: title,  // Prefill the title field with the corresponding title
			}));
		  } else {
			// If no title field exists, only update the full name field
			setInviteSignatoriesState((prevData) => ({
			  ...prevData,
			  [name]: [selectedOption],
			}));
		  }
		}
	  };
	  
	  

	// Handle text input change
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		// Prevent update if the value contains digits for specific fields
		const fieldsToCheck = [
			'certificate1FullName',
			'certificate2FullName',
			'equityPlanFullName',
			'convertiblesFullName',
			'warrantsFullName',
		];
		if (
			fieldsToCheck.includes(name) &&
			containsDigit(value) &&
			value.length > 0
		) {
			return; // Do not update state if the value contains a digit
		}

		setInviteSignatoriesState((prevData) => ({
			...prevData,
			[name]: value,
		}));

		// Validation for required fields
		const field = SignatoriesFormFields.sections
			.flatMap((section) => section.fields)
			.find((field) => field.name === name);

		if (field?.isRequired && !value.trim()) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: `${field.label} is required`,
			}));
		} else {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: '',
			}));
		}
	};

	const getCustomOptions = useCallback(
		({
			data,
			innerRef,
			innerProps,
		}: {
			data: ISelectOption;
			innerRef: React.Ref<HTMLDivElement>;
			innerProps: any;
		}) => {
			return (
				<div
					className="InviteSignatories__dropdown-option"
					ref={innerRef}
					{...innerProps}
				>
					<div className="InviteSignatories__dropdown-option--header">
						<div className="InviteSignatories__dropdown-option--text">
							<div className="InviteSignatories__dropdown-option--label">
								{data.label}
							</div>
						</div>
					</div>
				</div>
			);
		},
		[]
	);
	return (
		<div className="InviteSignatories">
			<div className="InviteSignatories__heading">
				{SignatoriesFormFields.heading}
			</div>
			<SignatoryForm
				sections={memberTitle?.length ? updatedSections : SignatoriesFormFields?.sections}
				handleChange={handleChange}
				formData={inviteSignatories}
				errors={errors}
				getCustomOptions={getCustomOptions}
				handleChangeSelect={handleChangeSelect}
			/>
		</div>
	);
};
