import type { IReactSwitch as Props } from './type';
import Switch from 'react-switch';

/**
 * ReactSwitch Component
 * A customizable switch component built with `react-switch`.
 *
 * @param {string} id - The unique identifier for the switch element.
 * @param {boolean} [checked=false] - The current state of the switch (checked or unchecked).
 * @param {(checked: boolean) => void} handleChange - Callback function invoked when the switch state changes.
 * @param {number} [width=48] - The width of the switch component.
 * @param {number} [height=24] - The height of the switch component.
 * @param {string} [onColor="#4574f5"] - The color of the switch when it is in the "on" state.
 * @param {string} [offColor="#C9CDD6"] - The color of the switch when it is in the "off" state.
 * @param {number} [diameter=16] - The diameter of the handle of the switch.
 * @param {boolean} [isDisabled=false] - Flag indicating whether the switch is disabled.
 * @param {string} [className=""] - Additional CSS classes for styling the switch.
 * @returns {JSX.Element} A React switch component.
 */

export const ReactSwitch = ({
	id,
	checked = false,
	handleChange,
	width = 48,
	height = 24,
	onColor = '#4574f5',
	offColor = '#C9CDD6',
	diameter = 16,
	isDisabled = false,
	className = '',
}: Props): JSX.Element => {
	return (
		<Switch
			id={id} /* Unique identifier for the switch element */
			checked={checked} /* Current state of the switch */
			disabled={isDisabled} /* Disable the switch if true */
			checkedIcon={false} /* Hides the "checked" icon */
			uncheckedIcon={false} /* Hides the "unchecked" icon */
			onColor={onColor} /* Color for "on" state */
			offColor={offColor} /* Color for "off" state */
			height={height} /* Height of the switch */
			width={width} /* Width of the switch */
			handleDiameter={diameter} /* Diameter of the switch handle */
			className={className} /* Custom CSS classes for the switch */
			onChange={handleChange} /* Callback for state changes */
		/>
	);
};
