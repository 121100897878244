import countries from './json/country-codes.json';

export const fetchCountryCodeFromPhone = (
	phoneNumber: string,
	threshold = 7
) => {
	let maxMatch = -1;
	let matchingCountryCode = null;
	countries.forEach(obj => {
		const countryCode = obj.label.replace('+', '');
		if (phoneNumber.startsWith('+' + countryCode)) {
			const countryCodeLength = obj.label.length;
			const matchPercentage = (countryCodeLength / phoneNumber.length) * 100;
			if (matchPercentage > threshold && countryCodeLength > maxMatch) {
				maxMatch = countryCodeLength;
				matchingCountryCode = obj.label;
			}
		}
	});

	return matchingCountryCode;
};