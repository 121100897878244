import React from 'react';
import './tab-navigation.scss';

interface TabNavigationProps {
  activeTab: string;
  onTabChange: (tab: string) => void;
}

const TabNavigation: React.FC<TabNavigationProps> = ({ activeTab, onTabChange }) => {
  const tabs = [
    { key: 'Administration', label: 'Company members' },
    { key: 'BoardMember', label: 'Board members' },
    { key: 'Signatories', label: 'Signatories' },
    { key: 'LawFirm', label: 'Law firms' },
  ];

  return (
    <nav className="tab-navigation">
      {tabs.map((tab) => (
        <div
          key={tab.key}
          className={`tab ${activeTab === tab.key ? 'active' : ''}`}
          onClick={() => onTabChange(tab.key)}
        >
          {tab.label}
        </div>
      ))}
    </nav>
  );
};

export default TabNavigation;

