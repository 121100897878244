export const AdministratorInformationFormDetails = [
	{
		title: 'Administrator Information',
		fields: [
			{
				label: 'First name',
				name: 'firstName',
				type: 'text',
				placeholder: 'Enter first name',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Last name',
				name: 'lastName',
				type: 'text',
				placeholder: 'Enter last name',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Email',
				name: 'email',
				type: 'text',
				placeholder: 'Enter email',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Phone Number',
				name: 'phoneNumber',
				type: 'phoneNumber',
				placeholder: 'Enter phone number',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Titles',
				name: 'titles',
				type: 'text',
				placeholder: 'Add titles',
				isRequired: true,
				width: 100,
			},

			{
				label: 'Permissions',
				name: 'permissions',
				type: 'dropdown',
				placeholder: 'Select permissions',
				isRequired: true,
				isDisabled: false,
				width: 100,
				options: [
					{
						label: 'View Only',
						description:
							'View-only access limited to the cap table and reports',
						value: 'User',
						number: 0,
					},
					{
						label: 'Manager',
						description: 'Edit access to most workflows',
						value: 'Manager',
						number: 1,
					},
					{
						label: 'Full Access',
						description: 'Edit and approval access to all workflows',
						value: 'Admin',
						number: 2,
					},
				],
			},
		],
	},
];
