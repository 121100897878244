import { atom } from 'recoil';
import { IIsOpenInviteSignatoriesState, IMemberTitlesState } from './type';

// Atom for modal open state
export const isOpenInviteSignatoriesModalState = atom<boolean>({
	key: 'is-open-invite-signatories-modal-state',
	default: false,
});

// Atom for invite signatories state
export const isOpenInviteSignatoriesState = atom<IIsOpenInviteSignatoriesState>(
	{
		key: 'is-open-invite-signatories-state-key',
		default: {
			certificate1FullName: '',
			certificate1Title: '',
			certificate2FullName: '',
			certificate2Title: '',
			equityPlanFullName: '',
			convertiblesFullName: '',
			convertiblesTitle: '',
			warrantsFullName: '',
		}, // Default is an empty object, but it should conform to IInviteSignatoriesState
	}
);

export const memberTitlesState = atom<IMemberTitlesState[]>({
    key: 'signatories-state',
    default: [],
});