import { FC } from 'react';
interface IInviteAdministratorHeader {
	title: string;
	subTitle: string;
}
export const InviteAdministratorHeader: FC<IInviteAdministratorHeader> = ({
	title,
	subTitle,
}) => {
	return (
		<div className="InviteAdministratorHeader__header">
			<div className="InviteAdministratorHeader__image">
				<i className="ri-pie-chart-box-fill"></i>
			</div>
			<div className="InviteAdministratorHeader__title">{title}</div>
			<p className="InviteAdministratorHeader__subTitle">{subTitle}</p>
		</div>
	);
};
