import React from 'react';
import { ICompany } from '../../../company-registration-form/store';

import './company-list-popup.scss';

interface ICompanyListPopup {
	items: ICompany[];
	selected: string | undefined;
	onSelect: (item: ICompany) => void;
	onCreateCompany: () => void;
}

export const CompanyListPopup: React.FC<ICompanyListPopup> = ({
	items,
	selected,
	onSelect,
	onCreateCompany,
}) => {
	return (
		<div className="popup">
			<ul className="popup__list">
				{items?.map((item) => (
					<li
						key={item?.id}
						className={`popup__item ${selected === item?.id ? 'popup__item--selected' : ''}`}
						onClick={() => onSelect(item)}
					>
						<span className='popup__item--checked'>
							{selected === item.id && (
								<i className="ri-check-line popup__check-icon"></i>
							)}
						</span>
						<span className='popup__item--text'> {item?.companyName}</span>
					</li>
				))}
			</ul>
			<div className="popup__create" onClick={onCreateCompany}>
				<i className="ri-add-circle-line popup__icon"></i>
				Create Company
			</div>
		</div>
	);
};