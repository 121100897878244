import { atom } from 'recoil';
import { IInviteAdministratorState } from './type';

// Atom for modal open/close state
export const IsOpenInviteAdministratorModal = atom<boolean>({
	key: 'is-open-invite-administrator',
	default: false,
});

// Atom for Invite Administrator state
export const InviteAdministratorState = atom<IInviteAdministratorState>({
	key: 'invite-administrator-state',
	default: {
		firstName: '', // Default empty string for text input
		lastName: '',
		email: '',
		titles: '', // Default empty array for multi-select
		permissions: [], // Default empty array for dropdown
		countryCode:'+1',
		phoneNumber:''
	},
});
