import { useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userRoleState } from '../../../../hooks';
import { FormSection } from '../../../company-registration-form';
import { ISelectOption } from '../../../invite-administrator-modal';
import { BoardMemberInformationFormDetails } from '../../constant';
import { InviteBoardMemberState } from '../../store';

import './invite-board-member.scss';
import { containsDigit, isValidEmail } from '../../../../utils';

export const InviteBoardMember = ({
	onPhoneValidationChange,
}: {
	onPhoneValidationChange: (isValid: boolean) => void;
}) => {
	const [inviteBoardMemberState, setInviteBoardMemberState] = useRecoilState(
		InviteBoardMemberState
	);
	const [errors, setErrors] = useState({});
	const roles = useRecoilValue(userRoleState);

	// Map roles to dropdown options
	const mappedroles = roles?.map((role) => ({
		label: role.name,
		value: role.id,
	}));

	const handleValidation = (isValid: boolean) => {
		onPhoneValidationChange(isValid);
	};

	// Handle dropdown/multi-select change
	const handleChangeSelect = useCallback(
		(selectedOption: ISelectOption, name: string) => {
			// Check if the selected option's label matches any role
			const matchedRole = mappedroles.find(
				(role) => role.label === selectedOption.value
			);
			
			// Create a processed option with the matched value
			const processedOption = {
				...selectedOption,
				value: matchedRole?.value ?? '', // Use matched value or fallback
			};
	
			// Update state with the processed option
			setInviteBoardMemberState((prevData) => ({
				...prevData,
				[name]: [processedOption], // Store as an array
			}));
	
			// Additional logic for permissions and validation...
		},
		[mappedroles, setInviteBoardMemberState]
	);

	const handleSelectCountryCode = useCallback((countryCode: string) => {
		setInviteBoardMemberState((prevState) => ({
			...prevState,
			countryCode: countryCode,
		}));
	}, []);

	const handleCountryChange = useCallback(
		(phoneData: { countryCode: string | number; phone: string }) => {
			setInviteBoardMemberState((prevState) => ({
				...prevState,
				countryCode: phoneData.countryCode,
				phoneNumber: phoneData.phone,
			}));
		},
		[]
	);

	// Handle text input change
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		// Block updating state if the value is numeric for specific fields
		if (
			(name === 'firstName' || name === 'lastName') &&
			containsDigit(value) &&
			value.length > 0
		) {
			return; // Exit early if the value is numeric
		}

		setInviteBoardMemberState((prevData) => ({
			...prevData,
			[name]: value,
		}));

		if (name === 'email' && value.length > 0) {
			if (!isValidEmail(value)) {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: `Invaid email address`,
				}));
				return;
			}
		}


		// Validation for required fields
		const field = BoardMemberInformationFormDetails.flatMap(
			(section) => section.fields
		).find((field) => field.name === name);

		if (field?.isRequired && !value.trim()) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: `${field.label} is required`,
			}));
		} else {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: '',
			}));
		}
	};

	const minDate = useMemo(() => {
		// Check if 'endDate' exists in the fields
		const hasEndDateField = BoardMemberInformationFormDetails.some((section) =>
		  section.fields.some((field) => field.name === 'endDate')
		);
	  
		if (hasEndDateField && inviteBoardMemberState?.startDate) {
		  return inviteBoardMemberState?.startDate;
		}
	  
		return ''; // Default value if 'endDate' is not present or no valid startDate
	  }, [inviteBoardMemberState?.startDate]);

	const getCustomOptions = useCallback(
		({
			data,
			innerRef,
			innerProps,
		}: {
			data: ISelectOption;
			innerRef: React.Ref<HTMLDivElement>;
			innerProps: any;
		}) => {
			return (
				<div
					className="InviteAdminisitrator__dropdown-option"
					ref={innerRef}
					{...innerProps}
				>
					<div className="InviteAdminisitrator__dropdown-option--header">
						<div className="InviteAdminisitrator__dropdown-option--text">
							<div className="InviteAdminisitrator__dropdown-option--label">
								{data.label}
							</div>
							<div className="InviteAdminisitrator__dropdown-option--description">
								{data.description}
							</div>
						</div>
					</div>
				</div>
			);
		},
		[]
	);

	return (
		<div className="InviteBoardMember">
			{BoardMemberInformationFormDetails.map((section: any, index: any) => (
				<FormSection
					key={index}
					title={section.title}
					body={section.fields}
					handleChange={handleChange}
					formData={inviteBoardMemberState}
					errors={errors}
					handleChangeSelect={handleChangeSelect}
					getCustomOptions={getCustomOptions}
					handleSelectCountryCode={handleSelectCountryCode}
					handleCountryChange={handleCountryChange}
					defaultCountryCode={inviteBoardMemberState.countryCode}
					defaultNumber={inviteBoardMemberState.phoneNumber}
					handleValidation={handleValidation}
					minDate ={minDate}
				/>
			))}
		</div>
	);
};
