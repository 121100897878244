import { useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
	InviteAdministratorModal,
	IsOpenInviteAdministratorModal,
} from "../invite-administrator-modal";
import {
	InviteBoardMemberModal,
	IsOpenInviteBoardMemberModal,
} from "../invite-board-member-modal";
import { InviteLegalContactModal } from "../invite-legal-contact-modal/invite-legal-contact-modal";
import { IsOpenInviteLegalContactModal } from "../invite-legal-contact-modal/store";
import {
	InviteSignatoriesModal,
	isOpenInviteSignatoriesModalState,
} from "../invite-signatories-modal";
import { TeamsBodySection, TeamsSectionWrapper } from "./components";
import { isActiveTab } from "./stores/state";

import "./teams.scss";


export const Teams = () => {
	const activeTab = useRecoilValue<string>(isActiveTab);
	const [isOpenInviteSignatoriesModal, setIsOpenInviteSignatoriesModal] =
		useRecoilState(isOpenInviteSignatoriesModalState);
	const [isOpenInviteAdministratorModal, setIsOpenInviteAdministratorModal] =
		useRecoilState(IsOpenInviteAdministratorModal);
	const [isOpenInviteBoardMemberModal, setIsOpenInviteBoardMemberModal] =
		useRecoilState(IsOpenInviteBoardMemberModal);
	const [isOpenInviteLegalContactModal, setIsOpenInviteLegalContactModal] =
		useRecoilState(IsOpenInviteLegalContactModal);

	const handleAddMember = (activeTab:string) => {
		switch (activeTab) {
			case "LawFirm":
				setIsOpenInviteLegalContactModal(true);
				break;

			case "Signatories":
				setIsOpenInviteSignatoriesModal(true);
				break;

			case "Administration":
				setIsOpenInviteAdministratorModal(true);
				break;

			case "BoardMember":
				setIsOpenInviteBoardMemberModal(true);
				break;

			default:
				setIsOpenInviteAdministratorModal(true);
				break;
		}
	};

	const getButtonLabel = useCallback((tab: string): string => {
		switch (tab) {
		  case "LawFirm":
			return "Add law firm";
		  case "Signatories":
			return "Update signatory";
		  case "Administration":
			return "Add administrator";
		  case "BoardMember":
			return "Add member";
		  default:
			return "Invite Member";
		}
	  }, []);

	return (
		<>
			<div className="Teams">
				<TeamsSectionWrapper
					headerTitle="Team"
					buttonLabel={getButtonLabel(activeTab)}
					onButtonClick={()=>handleAddMember(activeTab)}
				>
					<TeamsBodySection />
				</TeamsSectionWrapper>
			</div>
			{isOpenInviteAdministratorModal && <InviteAdministratorModal/>}
			{isOpenInviteBoardMemberModal && <InviteBoardMemberModal />}
			{isOpenInviteLegalContactModal && <InviteLegalContactModal />}
			{isOpenInviteSignatoriesModal && <InviteSignatoriesModal isFromTeam = {true}/>}
		</>
	);
};
