import { FC, useMemo } from 'react';

import './loader.scss';

interface ILoaderDimension {
	dimension?: number;
	className?: 'loader-blue' | 'loader-white' | 'loader-black';
	type?: string;
}

export const Loader: FC<ILoaderDimension> = ({
	dimension = 45,
	className = 'loader-white',
}) => {
	const renderMainComponent = useMemo(() => {
		return (
			<div className="circle-loader">
				<div
					style={{ width: dimension, height: dimension }}
					className={`loader ${className}`}
				/>
			</div>
		);
	}, [className, dimension]);
	return renderMainComponent;
};
