export const SignatoriesFormFields = {
	heading: 'Signatories information',
	sections: [
		{
			section: 'Certificate (1st)',
			fields: [
				{
					label: 'Full name',
					name: 'certificate1FullName',
					type: 'text',
					placeholder: 'Enter full name',
					isRequired: true,
					width: 100,
				},
				{
					label: 'Title',
					name: 'certificate1Title',
					type: 'text',
					placeholder: 'Enter title',
					isRequired: true,
					width: 100,
				},
			],
		},
		{
			section: 'Certificate (2nd)',
			fields: [
				{
					label: 'Full name',
					name: 'certificate2FullName',
					type: 'text',
					placeholder: 'Enter full name',
					isRequired: true,
					width: 100,
				},
				{
					label: 'Title',
					name: 'certificate2Title',
					type: 'text',
					placeholder: 'Enter title',
					isRequired: true,
					width: 100,
					
				},
			],
		},
		{
			section: 'Equity plan awards',
			fields: [
				{
					label: 'Full name',
					name: 'equityPlanFullName',
					type: 'text',
					placeholder: 'Enter full name',
					isRequired: true,
					width: 100,
				},
			],
		},
		{
			section: 'Convertibles',
			fields: [
				{
					label: 'Full name',
					name: 'convertiblesFullName',
					type: 'text',
					placeholder: 'Enter full name',
					isRequired: true,
					width: 100,
				},
				{
					label: 'Title',
					name: 'convertiblesTitle',
					type: 'text',
					placeholder: 'Enter title',
					isRequired: true,
					width: 100,
					
				},
			],
		},
		{
			section: 'Warrants',
			fields: [
				{
					label: 'Full name',
					name: 'warrantsFullName',
					type: 'text',
					placeholder: 'Enter full name',
					isRequired: true,
					width: 100,
				},
			],
		},
	],
};


// Type guard for handling array or string
export const getFullName = (fullName: string | { value: string }[]) => {
	if (Array.isArray(fullName)) {
		// If it's an array, get the first value's value property
		return fullName[0]?.value ?? ''; // Fallback to empty string if no value
	}
	// If it's a string, return it directly
	return fullName;
};