export const CompanyRegistrationFormDetails = [
	{
		title: 'Company details',
		fields: [
			{
				label: 'Company name',
				name: 'companyName',
				type: 'text',
				placeholder: 'Enter company name',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Website',
				name: 'website',
				type: 'text',
				placeholder: 'Enter company website',
				isRequired: false,
				width: 50,
			},
			{
				label: 'Your job title',
				name: 'jobTitle',
				type: 'text',
				placeholder: 'Enter your job title',
				isRequired: false,
				width: 100,
			},
			{
				label: 'DBA',
				name: 'dba',
				type: 'text',
				placeholder: 'Enter DBA',
				isRequired: false,
				width: 100,
			},
			{
				label: 'Industry sector',
				name: 'industrySector',
				type: 'text',
				placeholder: 'Enter industry sector',
				isRequired: true,
				width: 100,
			},
			{
				label: 'State of formation',
				name: 'stateOfFormation',
				type: 'text',
				placeholder: 'Enter state of formation',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Date of formation',
				name: 'dateOfFormation',
				type: 'date',
				placeholder: 'dd/mm/yyyy',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Tax ID',
				name: 'taxId',
				type: 'text',
				placeholder: 'Enter Tax ID',
				isRequired: true,
				width: 50,
			},
			{
				label: 'TCC',
				name: 'tcc',
				type: 'text',
				placeholder: 'Enter TCC',
				isRequired: false,
				width: 50,
			},
			{
				label: 'Accountant',
				name: 'accountant',
				type: 'text',
				placeholder: 'Enter accountant details',
				isRequired: false,
				width: 100,
			},
		],
	},
	{
		title: 'Company incorporation details',
		subtitle: 'Your certificate of incorporation will come in handy here',
		fields: [
			{
				label: 'Incorporation type',
				name: 'incorporationType',
				type: 'text',
				placeholder: 'Enter incorporation type',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Incorporation date',
				name: 'incorporationDate',
				type: 'date',
				placeholder: 'Select incorporation date',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Incorporation country',
				name: 'incorporationCountry',
				type: 'text',
				placeholder: 'Enter incorporation country',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Incorporation state',
				name: 'incorporationState',
				type: 'text',
				placeholder: 'Enter incorporation state',
				isRequired: true,
				width: 50,
			},
		],
	},
	{
		title: 'Company address',
		subtitle: "Please provide your company's address",
		fields: [
			{
				label: 'Street address',
				name: 'streetAddress',
				type: 'auto-fill',
				placeholder: 'Street address',
				isRequired: true,
				width: 50,
			},
			{
				label: 'City',
				name: 'city',
				type: 'text',
				placeholder: 'Enter city',
				isRequired: true,
				width: 50,
			},
			{
				label: 'State',
				name: 'state',
				type: 'text',
				placeholder: 'Enter state',
				isRequired: true,
				width: 31.5,
			},
			{
				label: 'Zip code',
				name: 'zipCode',
				type: 'text',
				placeholder: 'Enter zip code',
				isRequired: true,
				width: 31.5,
			},
			{
				label: 'Country',
				name: 'country',
				type: 'text',
				placeholder: 'Enter country',
				isRequired: true,
				width: 31.5,
			},
		],
	},
	{
		title: 'Additional Info',
		fields: [
			{
				label: 'Business description',
				name: 'businessDescription',
				type: 'textarea',
				placeholder: 'Enter any additional information',
				width: 100,
				isRequired: false,
			},
		],
	},
];


export  const COMPANY_STATUS_MESSAGES = {
	CREATED: 'Company created successfully!',
	UPDATED: 'Company updated successfully!',
	ERROR : 'Something went wrong!'
  };

export const ValidateField = ['industrySector','stateOfFormation','incorporationCountry','incorporationState','city','state','country']

export const CompanyRegistrationFormName = {
	WEBSITE : 'website',
	ZIPCODE:'zipCode',
	TAXID:'taxId'
}