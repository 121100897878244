export const convertFileToBase64 = (file: any) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = reject;
	});
};

export const isBase64 = (str: string) => {
	const base64Pattern = /^(data:([a-zA-Z0-9]+\/[a-zA-Z0-9\-\+]+);base64,)([A-Za-z0-9+/=\r\n]+)$/; return base64Pattern.test(str);
  };