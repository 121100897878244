import { FocusEventHandler } from 'react';
import Select from 'react-select';

import './dropdown.scss';

export type IOption = { label: string; value: any } | any;

type IMenuType = 'auto' | 'top' | 'bottom';

interface Props {
	handleChangeSelect?: (event: IOption) => void;
	options: IOption[];
	value?: IOption;
	defaultValue?: IOption;
	isSearchable?: boolean;
	isMulti?: boolean;
	createStyle?: any;
	controlStyle?: any;
	menuStyle?: any;
	multiValueStyle?: any;
	multiValueRemoveStyle?: any;
	optionsStyle?: any;
	Isdisable?: boolean;
	placeholder?: string | JSX.Element;
	label?: string;
	handleBlur?: FocusEventHandler<HTMLInputElement>;
	isRequired?: boolean;
	optionsDropHeight?: number | null;
	errorMessage?: string;
	maxMenuHeight?: number | undefined;
	closeMenuOnSelect?: boolean;
	description?: string;
	footerLabel?: JSX.Element | string;
	handleFooterLabelClick?: () => void;
	isCustomComponent?: boolean;
	focusValue?: string;
	getCustomOptions?: any;
	name?: string;
}

export const ReactDropdown = ({
	handleChangeSelect,
	options,
	value,
	defaultValue,
	isSearchable = false,
	isMulti = false,
	createStyle,
	controlStyle = {},
	menuStyle = {},
	multiValueStyle = {},
	multiValueRemoveStyle = {},
	optionsStyle = {},
	Isdisable,
	placeholder = 'Select',
	handleBlur,
	label,
	isRequired = false,
	optionsDropHeight = null,
	errorMessage,
	maxMenuHeight = undefined,
	closeMenuOnSelect = false,
	description = '',
	isCustomComponent = false,
	focusValue = '',
	getCustomOptions,
	name,
}: Props) => {
	const customStyle = {
		control: (styles: { [key: string]: number | string }) => ({
			...styles,
			backgroundColor: !errorMessage ? '#fff' : 'rgba(255,0,0,.06);',
			minHeight: 44.5,
			border: !errorMessage
				? '1px solid var(--color-white-100)'
				: '1px solid var(--color-error)',
			borderRadius: '0.375rem',
			fontWeight: '500',
			color: '#fff',
			boxShadow: 'none',
			'&:hover': {
				cursor: 'pointer',
				borderColor: '#ced2de',
			},
			'&:focus': {
				borderColor: 'red',
			},
			...controlStyle,
		}),
		placeholder: (styles: any) => ({
			...styles,
			color: '#9ca3af',
			fontSize: '1rem',
			fontWeight: '400',
			border: 'none',
			outline: 'none',
		}),
		menu: (styles: { [key: string]: any }) => ({
			...styles,
			background: '#fff',
			borderRadius: 8,
			border: '1px solid #ced2de',
			zIndex: 3,
			overflow: 'hidden',
			...menuStyle,
		}),
		multiValue: (styles: { [key: string]: any }) => ({
			...styles,
			backgroundColor: '#F3F4F6',
			color: 'rgba(47, 50, 61, 1)',
			borderRadius: 50,
			paddingLeft: 8,
			paddingRight: 8,
			paddingTop: 4,
			paddingBottom: 4,
			margin: 4,
			...multiValueStyle,
		}),

		multiValueRemove: (styles: any) => ({
			...styles,
			color: 'rgba(163, 177, 214, 1)',
			borderRadius: 50,
			marginLeft: 2,
			fontSize: 12,
			...multiValueRemoveStyle,
		}),
		option: (styles: { [key: string]: any }, { data }: any) => {
			const { fontFamily } = data ?? {};
			return {
				...styles,
				fontFamily,
				backgroundColor: data.value === focusValue ? '#3c65d6' : '#fff',
				color: data.value === focusValue ? '#fff' : '#000',
				height: 42,
				cursor: 'pointer',
				paddingTop: 12,
				paddingBottom: 12,
				borderBottom: '1px solid #ced2de',
				fontWeight: '500',
				'&:hover': {
					color: '#374151',
					backgroundColor: '#F3F4F6',
				},
				'&:last-child': {
					borderBottom: 'none',
				},
				...optionsStyle,
			};
		},
		valueContainer: (provided: any) => ({
			...provided,
			textOverflow: 'ellipsis',
			overflowY: 'scroll',
			display: 'flex',
			maxHeight: '130px',
		}),
	};

	const scrollProps = {
		...(maxMenuHeight && {
			maxMenuHeight: maxMenuHeight,
			menuPlacement: 'auto' as IMenuType,
		}),
	};

	const customComponent: any = {
		IndicatorSeparator: () => null,
	};

	if (getCustomOptions) {
		customComponent['Option'] = getCustomOptions;
	}

	return (
		<div
			id="dropdown__container"
			className={`${Isdisable ? 'dropdown__container--disabled' : ''}`}
		>
			{label && (
				<label htmlFor={label} className="input__label">
					{label}
					{isRequired && <span className="input__isRequired"> (Required)</span>}
				</label>
			)}
			{description && (
				<div className="dropdown__container__description">{description}</div>
			)}
			<Select
				name={name}
				isDisabled={Isdisable}
				onBlur={handleBlur}
				defaultValue={defaultValue}
				isMulti={isMulti}
				value={value}
				placeholder={placeholder}
				className="dropdown"
				styles={createStyle ? createStyle : customStyle}
				options={options}
				isClearable={false}
				closeMenuOnSelect={closeMenuOnSelect || !isMulti}
				isSearchable={isSearchable}
				maxMenuHeight={optionsDropHeight ?? 300}
				onChange={handleChangeSelect as any}
				components={isCustomComponent ? getCustomOptions : customComponent}
				menuPlacement='auto'
				{...scrollProps}
			/>
			{/* Showing error message  */}
			{errorMessage && <span className="Error--message">{errorMessage}</span>}
		</div>
	);
};