import React from 'react';
import { CompanySetupDetailsCard } from '../company-setup-details-card';

import './company-setup-section.scss';

interface Task {
	title: string;
	description: string;
	buttonText: string;
	showRadio: boolean;
	companyInviteType: string;
}

interface Props {
	sectionTitle: string;
	tasks: Task[];
	onButtonClick: (taskTitle: string) => void;
	onRadioChange: (taskTitle: string) => void;
	selectedRadio: string | null;
	isDisabled?:boolean
}

export const CompanySetupSection: React.FC<Props> = ({
	sectionTitle,
	tasks,
	onButtonClick,
	onRadioChange,
	selectedRadio,
	isDisabled= false
}) => {
	return (
		<div className="CompanySetupSection">
			<div className="CompanySetupSection__title">{sectionTitle}</div>
			<div className="CompanySetupSection__tasks">
				{tasks.map((task, index) => (
					<CompanySetupDetailsCard
						key={index}
						task={task}
						onButtonClick={onButtonClick}
						onRadioChange={onRadioChange}
						isSelected={selectedRadio === task.title}
						isDisabled={task?.companyInviteType === 'rolesSignatories' && isDisabled}
					/>
				))}
			</div>
		</div>
	);
};