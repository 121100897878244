import { LawFirmInformationFormDetails } from '../../constant';
import { FormSection } from '../../../company-registration-form';
import { useCallback, useMemo, useState } from 'react';
import { InviteLegalContactState } from '../../store';
import { useRecoilState, useRecoilValue } from 'recoil';
import { selectedCompany } from '../../../company-registration-form/state';

import './invite-legal-contact.scss';
import { containsDigit, isValidEmail } from '../../../../utils';

export const InviteLegalContact = ({
	onPhoneValidationChange,
}: {
	onPhoneValidationChange: (isValid: boolean) => void;
}) => {
	const [errors, setErrors] = useState({});
	const [inviteLegalContactState, setInviteLegalContactState] = useRecoilState(
		InviteLegalContactState
	);

	const companyList = useRecoilValue(selectedCompany);
	const companyOptions = useMemo(() => {
		return companyList?.length
			? companyList?.map((company, index) => ({
					label: company.companyName, // Use companyName as label
					value: company.companyName, // Use companyName as value
					number: index + 1, // Optional: Add a number for display or ordering
				}))
			: [];
	}, [companyList]);

	const handleValidation = (isValid: boolean) => {
		onPhoneValidationChange(isValid);
	};

	// Handle dropdown/multi-select change
	const handleChangeSelect = (
		selectedOption: { label: string; value: string },
		name: string
	) => {
		// Update state with selected company name
		setInviteLegalContactState((prevData) => ({
			...prevData,
			[name]: [selectedOption], // Wrap in an array for consistent format
		}));

		// Clear error for the current field
		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: '', // Reset the error for this field
		}));
	};

	const handleSelectCountryCode = useCallback((countryCode: string) => {
		setInviteLegalContactState((prevState) => ({
			...prevState,
			countryCode: countryCode,
		}));
	}, []);

	const handleCountryChange = useCallback(
		(phoneData: { countryCode: string | number; phone: string }) => {
			setInviteLegalContactState((prevState) => ({
				...prevState,
				countryCode: phoneData.countryCode,
				phoneNumber: phoneData.phone,
			}));
		},
		[]
	);

	// Handle text input change
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		// Block updating state if the value is numeric for specific fields
		if (
			(name === 'firstName' || name === 'lastName') &&
			containsDigit(value) &&
			value.length > 0
		) {
			return; // Exit early if the value is numeric
		}

		setInviteLegalContactState((prevData) => ({
			...prevData,
			[name]: value,
		}));

		if (name === 'email' && value.length > 0) {
			if (!isValidEmail(value)) {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: `Invaid email address`,
				}));
				return;
			}
		}

		// Validation for required fields
		const field = LawFirmInformationFormDetails.flatMap(
			(section) => section.fields
		).find((field) => field.name === name);

		if (field?.isRequired && !value.trim()) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: `${field.label} is required`,
			}));
		} else {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: '',
			}));
		}
	};

	const getCustomOptions = useCallback(
		({ data, innerRef, innerProps }: any) => {
			return (
				<div
					className="InviteLegalContact__dropdown-option"
					ref={innerRef}
					{...innerProps}
				>
					<div className="InviteLegalContact__dropdown-option--header">
						<div className="InviteLegalContact__dropdown-option--text">
							<div className="InviteLegalContact__dropdown-option--label">
								{data.label}
							</div>
							<div className="InviteLegalContact__dropdown-option--description">
								{data.description}
							</div>
						</div>
					</div>
				</div>
			);
		},
		[]
	);

	return (
		<div className="InviteLegalContact">
			{LawFirmInformationFormDetails.map((section, index) => (
				<FormSection
					key={index}
					title={section.title}
					body={section.fields.map((field) => {
						if (field.name === 'lawFirmName') {
							return {
								...field,
								options: companyOptions, // Use the dynamic options here
							};
						}
						return field;
					})}
					handleChange={handleChange}
					formData={inviteLegalContactState}
					errors={errors}
					handleChangeSelect={handleChangeSelect}
					getCustomOptions={getCustomOptions}
					handleSelectCountryCode={handleSelectCountryCode}
					handleCountryChange={handleCountryChange}
					defaultCountryCode={inviteLegalContactState.countryCode}
					defaultNumber={inviteLegalContactState.phoneNumber}
					handleValidation={handleValidation}
				/>
			))}
		</div>
	);
};
