import { FC, MouseEvent, createRef, useCallback, useState } from 'react';
import './info-dropdown-options.scss';
import { useOutsideClick } from '../../../../hooks/use-outside-click';
import { IconBtn } from '../icon-btn';
import { Loader } from '../../../../@storybook/loader';

export interface IDropOptions {
	handleEdit?: (e?: MouseEvent<HTMLDivElement>) => void;
	handleDelete: (e: MouseEvent<HTMLDivElement>) => void;
	editLabel?: string;
	deleteLabel?: string;
	showEdit?: boolean;
	blockedLabel?: string;
	handleBlockedUser?: (e: MouseEvent<HTMLDivElement>) => void;
	isDisabled?: boolean;
	showReinvite?: boolean;
	handleReInvite?: (e?: MouseEvent<HTMLDivElement>) => void;
	isReInviteLoading?: boolean;
	isDeleteDisabled?: boolean;
}

export const DropInfoOptions: FC<IDropOptions> = ({
	handleEdit,
	handleDelete,
	deleteLabel = 'Delete',
	editLabel = 'Edit',
	showEdit = true,
	blockedLabel,
	handleBlockedUser,
	isDisabled = false,
	showReinvite = false,
	handleReInvite,
	isReInviteLoading = false,
	isDeleteDisabled = false,
}) => {
	// local state
	const [showOption, setShowOption] = useState<boolean>(false);

	// ref
	const ref = createRef<HTMLDivElement>();

	useOutsideClick(ref, () => {
		setShowOption(false);
	});

	const handleOptions = useCallback((e: MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		setShowOption(prev => !prev);
	}, []);

	const handleclickOnEdit = useCallback(
		(e?: MouseEvent<HTMLDivElement>) => {
			setShowOption(false);
			if (handleEdit) handleEdit(e);
		},
		[handleEdit]
	);

	const handleclickOnDelete = useCallback(
		(e: MouseEvent<HTMLDivElement>) => {
			setShowOption(false);
			handleDelete(e);
		},
		[handleDelete]
	);

	const handleBlocked = (e: MouseEvent<HTMLDivElement>) => {
		setShowOption(false);
		if (handleBlockedUser) handleBlockedUser(e);
	};

	return (
		<div ref={ref} className='options-wrapper'>
			<IconBtn
				isDisabled={isDisabled}
				className="extra"
				btnClassName="ri-more-2-fill"
				handleClickIcon={handleOptions}
			/>
			{showOption && (
				<div className="options-box">
					{showEdit && (
						<div onClick={e => handleclickOnEdit(e)} className="dropdowns-item">
							{/* <i className="ri-user-settings-line space" /> */}
							{editLabel}
						</div>
					)}
					<div className={`dropdowns-item ${!isDeleteDisabled || 'disabled-item' }`} onClick={e => !isDeleteDisabled && handleclickOnDelete(e)} >
						{/* <i className="ri-user-unfollow-line space" /> */}
						{deleteLabel}
					</div>

					{blockedLabel && (
						<div className="dropdowns-item" onClick={e => handleBlocked(e)}>
							{/* <i className="ri-forbid-line space"></i> */}
							{blockedLabel}
						</div>
					)}
					{showReinvite && (
						<div className="dropdowns-item" onClick={e => handleReInvite?.(e)}>
							{/* <i className="ri-refresh-line space"></i> */}
							<div>Reinvite</div>{' '}
							{isReInviteLoading && (
								<span>
									<Loader dimension={14} type="loader" />
								</span>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};
