import { useCallback } from 'react';
import { toast } from 'react-toastify';

export const useNotification = () => {
	const successNotification = useCallback((msg: string) => {
		toast.success(msg);
	}, []);

	const infoNotification = useCallback((msg: string) => {
		toast.info(msg);
	}, []);

	const errorNotification = useCallback((msg: string) => {
		toast.error(msg);
	}, []);

	const warningNotification = useCallback((msg: string) => {
		toast.warning(msg);
	}, []);

	return {
		successNotification,
		infoNotification,
		errorNotification,
		warningNotification,
	};
};
