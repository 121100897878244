import React, { useCallback, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { navData } from './constant';

import './left-nav.scss';

// Define types for navigation data
type NavChild = {
	label: string;
	link: string;
};

type NavItem = {
	label: string;
	icon: string;
	link?: string;
	children?: NavChild[];
};

type NavSection = {
	header: string;
	items: NavItem[];
};

export const Sidebar: React.FC = () => {
	const [openChildDropdown, setOpenChildDropdown] = useState<string[]>([]);
	const location = useLocation(); // Get the current location

	const toggleMenu = useCallback((label: string) => {
		setOpenChildDropdown((prevState) =>
			prevState.includes(label)
				? prevState.filter((item) => item !== label)
				: [...prevState, label]
		);
	}, []);

	const renderMenuSections = useMemo(() => {
		return (navData as NavSection[]).map((section, index) => {
			// Skip rendering the header for the "Setup" section
			const shouldShowHeader = section.header !== 'Setup';

			return (
				<div
					key={section.header || `section-${index}`}
					className={`menu-section ${section.header}`}
				>
					{shouldShowHeader && (
						<li className="menu-header">{section.header}</li>
					)}
					{section.items.map((item) => {
						// Check if the item or any of its children are active
						const isActive =
							location.pathname === item.link ||
							(item.children &&
								item.children.some(
									(child) => location.pathname === child.link
								));

						return (
							<li key={item.label} className="menu-item">
								<div
									className={`menu-label ${item.children ? 'has-children' : ''} ${isActive ? 'active' : ''}`}
									onClick={() => item.children && toggleMenu(item.label)}
								>
									<i
										className={`icon ${item.icon} ${isActive ? 'active' : ''}`}
									/>
									<span>
										{item.children ? (
											item.label
										) : (
											<Link
												to={item.link || '#'}
												className={isActive ? 'active' : ''}
											>
												{item.label}
											</Link>
										)}
									</span>
									{item.children && (
										<span className="accordion-icon">
											<i
												className={
													openChildDropdown.includes(item.label)
														? 'ri-arrow-up-s-line'
														: 'ri-arrow-down-s-line'
												}
											></i>
										</span>
									)}
								</div>
								{item.children && openChildDropdown.includes(item.label) && (
									<ul className="submenu">
										{item.children.map((child) => {
											const isChildActive = location.pathname === child.link;

											return (
												<li key={child.label} className="submenu-item">
													<Link
														to={child.link}
														className={isChildActive ? 'active' : ''}
													>
														{child.label}
													</Link>
												</li>
											);
										})}
									</ul>
								)}
							</li>
						);
					})}
				</div>
			);
		});
	}, [location.pathname, openChildDropdown, toggleMenu]);

	return (
		<div className="sidebar">
			<ul className="menu">{renderMenuSections}</ul>
		</div>
	);
};